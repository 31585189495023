import { Injectable } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/delay';
import { environment } from '../../environments/environment';
import { CommonService } from './common.service';
import { Http, Response, RequestOptions, Headers } from '@angular/http';
const API_CARDS_URL = environment.api.card;
const smsUrl = environment.api.base;
const fileUrl: String = environment.api.fileUpload;

@Injectable()
export class CardsService {
  options: any ;
  token = localStorage.getItem('token');

  constructor(
    private commonService: CommonService,
    private http: Http
  ) {
    const decryptedString = this.commonService.setToken('decrypt', this.token);
    const headers = new Headers({ 'authorization': `Bearer ${decryptedString}` });
    this.options = new RequestOptions({ headers: headers });
   }

  getCardById(cardId) {
    return this.commonService.getById(`${API_CARDS_URL}/v1/cards/${cardId}`, this.token);
  }

  saveCard(card) {
    return this.commonService.create(`${API_CARDS_URL}/api/v1/card`, this.token, card);
  }

  editCard(card) {
    return this.commonService.edit(`${API_CARDS_URL}/api/v1/card`, this.token, card);
  }

  getCards(data) {
    return this.commonService.get(`${API_CARDS_URL}/api/v1/card`, this.token, {
      type: data.type,
      pageNumber: data.page,
      search: data.search,
      groupId: data.groupId
    });
  }

  deleteCardById(cardId) {
    return this.commonService.delete(`${API_CARDS_URL}/api/v1/card`, this.token, cardId);
  }

  sendSms(sms) {
    return this.http.post(smsUrl + '/api/twilio/sms', sms, this.options)
      .toPromise().then((res: Response) => res.json());
  }

  shareCard(data) {
    return this.http.put(`${API_CARDS_URL}/api/v1/card/share`, data, this.options)
      .toPromise().then((res: Response) => res.json());
  }

  uploadFile(obj) {
    return this.commonService.upload(fileUrl, obj);
  }

  deleteFile(file) {
    return this.http.delete(fileUrl + '/' + file, this.options)
      .toPromise().then((res: Response) => res.json());
  }

  uploadUserImage(_userImage) {
    return this.commonService.upload(fileUrl, _userImage);
  }

  getSharedCard(cardInfo) {
    return this.http.post(
      `${API_CARDS_URL}/v1/cards/updateView`, 
      cardInfo
    )
    .toPromise().then((res: Response) => res.json());
  }

  action(data) {
    return this.http.put(`${API_CARDS_URL}/api/v1/card/action`, data, this.options)
      .toPromise().then((res: Response) => res.json());
  }

  thirdPartyShare(data) {
    return this.http.put(`${API_CARDS_URL}/api/v1/card/third-party-share`, data, this.options)
      .toPromise().then((res: Response) => res.json());
  }

  audioPlayCount(cardId) {
    return this.http.put(`${API_CARDS_URL}/api/v1/card/audio-play-count`, cardId, this.options)
      .toPromise().then((res: Response) => res.json());
  }

  changeGroup(obj) {
    return this.commonService.edit(`${API_CARDS_URL}/api/v1/card/change-group`, this.token, obj);
  }

  trackUrlHit(data) {
    return this.http.put(`${API_CARDS_URL}/api/v1/card/track-url-hit`, data, this.options)
      .toPromise().then((res: Response) => res.json());
  }

}
