import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth/auth.guard';

import { LoginComponent } from './auth/login/login.component';
import { SignupComponent } from './auth/signup/signup.component';
import { AccountVerificationComponent } from './auth/account-verification/account-verification.component';
import { ForgetPasswordComponent } from './auth/forget-password/forget-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';

/** components */
import { LayoutComponent } from './Layout-main/layout/layout.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { HomeComponent } from './home/home.component';
import { UserComponent } from './components/user/user-list/user.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PlanComponent } from './components/plan/plan.component';
import { ProfileComponent } from './components/profile/profile.component';

/** card components */
import { CreateCardComponent } from './components/card/create-card/create-card.component';
import { AddCardComponent } from './components/card/add-edit-card/add-edit-card.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ListCardComponent } from './components/card/list-card/list-card.component';
import { ChangeEmailComponent } from './components/change-email/change-email.component';
import { ChangeOrganizationComponent } from './components/change-organization/change-organization.component';
import { InviteComponent } from './components/user/user-invite/invite.component';
import { GroupComponent } from './components/group/group.component';
import { ViewCardComponent } from './components/card/view-card/view-card.component';
import { PromotionComponent } from './components/promotion/promotion.component';
import { SharedCardComponent } from './components/card/shared-card/shared-card.component';
import { EventsComponent } from './components/events/events.component';
import { StaticPagesComponent } from './components/static-pages/static-pages.component';
import { TransactionComponent } from './components/transaction/transaction.component';
import { BroadcastComponent } from './components/broadcast/broadcast.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', component: HomeComponent, pathMatch: 'full'},
      { path: 'terms-and-conditions', component: LayoutComponent },
      { path: 'contactNew', component: LayoutComponent }
    ]
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
      }, {
        path: 'user',
        component: UserComponent
      }, {
        path: 'plan',
        component: PlanComponent
      }, {
        path: 'profile',
        component: ProfileComponent
      }, {
        path: 'create-card',
        component: CreateCardComponent
      }, {
        path: 'add-card',
        component: AddCardComponent
      }, {
        path: 'edit-card',
        component: AddCardComponent
      }, {
        path: 'my-card',
        component: ListCardComponent
      }, {
        path: 'user-card',
        component: ListCardComponent
      }, {
        path: 'view-card/:id',
        component: ViewCardComponent
      }, {
        path: 'change-password',
        component: ChangePasswordComponent
      }, {
        path: 'change-email',
        component: ChangeEmailComponent
      }, {
        path: 'change-organization',
        component: ChangeOrganizationComponent
      }, {
        path: 'invite',
        component: InviteComponent
      }, {
        path: 'group',
        component: GroupComponent
      }, {
        path: 'events',
        component: EventsComponent
      }, {
        path: 'promotions',
        component: PromotionComponent
      }, {
        path: 'transactions',
        component: TransactionComponent
      }, {
        path: 'broadcast',
        component: BroadcastComponent
      },
      //  {
      //   path: 'contact',
      //   component: ContactUsComponent
      // }
    ]

  },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  {
    path: 'account-verification/:token',
    component: AccountVerificationComponent
  },
  { path: 'magic-link-login', component: ForgetPasswordComponent },
  { path: 'forgot-password', component: ForgetPasswordComponent },
  { path: 'reset-password/:token', component: ResetPasswordComponent },
  { path: 'digital-card/:type/:slug/:pid', component: SharedCardComponent },
  { path: 'digital-card/:type/:slug', component: SharedCardComponent },
  { path: 'page/:slug', component: StaticPagesComponent },
  { path: '**', redirectTo: '', }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
