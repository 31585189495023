// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const jasdeepLocal = "http://172.24.5.81:3002";
const server = "https://api.dev.freemobicard.com";
//const server = "https://api.freemobicard.com";
//const server = "https://localhost:5001";
//const server = "https://192.168.86.9:45455";
const website = "http://localhost:4200";

const backendPath = server;

export const environment = {
  production: false,
  isMockEnabled: false,
  device: {
    iPhone: "businesscard://business",
    android: "businesscard://business"
  },
  linkedIn: {
    authUrl: "https://www.linkedin.com/oauth/v2/authorization",
    response_type: "code",
    client_id: "81izknkma0l4z0",
    redirect_uri: `${website}/login`
  },
  api: {
    //root: `${backendPath}/api/v1/`,
    root: `http://192.168.86.9:45455/api/v1/`,
    base: backendPath,
    website,
    server,
    fileUpload: `${backendPath}/api/file`,
    //user: `https://192.168.86.9:44373/api/v1/user`,
    user: `${backendPath}/api/v1/user`,
    subscription: `${backendPath}/api/v1/subscription`,
    card: `${backendPath}`,
    //card: `http://192.168.86.9:45456`,
    organization: `${backendPath}/api/v1/organization`,
    group: `${backendPath}/api/v1/group`,
    shareCardLink: `${website}/digital-card/`,
    notification: `${backendPath}/api/v1/notification`,
    event: `${backendPath}/api/v1/event`,
    adds: `${backendPath}/api/ads`,
    promotion: `${backendPath}/api/v1/promotion`,
    pages: `${backendPath}/api/v1/page`
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
